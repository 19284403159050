import '../common';

import '../components/advantage.css';
import '../components/blockquote';
import '../components/button';
import '../components/client.css';
import '../components/columnized.css';
import '../components/feature';
import '../components/footer';
import '../components/heading.css';
import '../components/logo';
import '../components/post.css';
import '../components/section.css';
import '../components/show-more';
import '../components/tile';
import '../components/error.css';
import '../components/contributors.css';
import '../components/icons.css';

import AnimLogo from '../components/anim-logo';
import Header from '../components/header';
import HeaderSearch from '../components/header-search';
import Subscribe from '../components/subscribe';
import request from '../utils/request';

document.addEventListener('DOMContentLoaded', () => {
  const getScrollY = () => window.scrollY || document.documentElement.scrollTop;

  const handleScroll = () => {
    document.documentElement.classList.toggle('has-scrolled', getScrollY());
  };

  /**
   * Scroll
   */
  handleScroll();
  window.addEventListener('scroll', () => handleScroll());

  /**
   * Header
   */
  const header = new Header();
  header.init();

  /**
   * Search
   */
  document.querySelectorAll('.js-header-search').forEach((node) => {
    const search = new HeaderSearch(node);
    search.init();
  });

  /**
   * Subscribe
   */
  const subscribe = new Subscribe();
  subscribe.init();

  const animLogo = new AnimLogo();
  animLogo.init();

  request('/contributors', {
    method: 'POST',
      headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    document.getElementById('contributors-block').innerHTML = response.html
  });
});

window.addEventListener('load', () => {
  document.documentElement.classList.add('is-app-ready');
});
