import { validateEmail } from '../../utils/validate';
import request from '../../utils/request';

export default class Subscribe {
  constructor() {
    this.selectors = {
      form: '.js-subscribe',
      input: '.js-subscribe-input',
      result: '.js-subscribe-result',
      captcha: '.js-captcha',
    };

    this.elements = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  bind() {
    document.querySelectorAll(this.selectors.form).forEach((form) => {
      this.elements[form.id] = {
        form,
        input: form.querySelector(this.selectors.input),
        captcha: form.querySelector(this.selectors.captcha),
        result: form.querySelector(this.selectors.result),
      };

      form.addEventListener('submit', (event) => {
        this.handleSubmit(event, form.id);
      });
    });
  }

  handleRequestSuccess(data, formId) {
    if (data.status !== 200) {
      this.showResult(this.elements[formId].form.getAttribute('data-error'), formId, false);
      return;
    }

    this.elements[formId].input.value = '';
    this.elements[formId].captcha.value = '';
    this.showResult(this.elements[formId].form.getAttribute('data-success'), formId, true);
  }

  handleSubmit(event, formId) {
    event.preventDefault();

    const { name, value } = this.elements[formId].input;
    if (!value || !validateEmail(value)) {
      this.showResult(this.elements[formId].input.getAttribute('data-error'), formId, false);
      return false;
    }

    const data = {
      [name]: value,
      captcha: this.elements[formId].captcha.value,
    };
    this.sendRequest(data, formId);

    return false;
  }

  sendRequest(data, formId) {
    const dataJson = JSON.stringify(data);

    request(this.elements[formId].form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: dataJson,
    }).then((response) => {
      this.handleRequestSuccess(response, formId);
    }).catch((errorData) => {
      this.handleRequestError(errorData, formId);
    });
  }

  showResult(message, formId, isSuccess) {
    const resultElement = this.elements[formId].result;
    resultElement.classList.remove('success', 'error');

    resultElement.innerHTML = message;
    resultElement.classList.add(isSuccess ? 'success' : 'error');
  }

  init() {
    this.bind();
  }
}
