import './header.css';

export default class Header {
  constructor() {
    this.elem = document.querySelector('.js-header');
    this.navToggleButton = this.elem.querySelector('.js-header-nav-toggle');

    this.handleToggleNavListener = this.handleToggleNav.bind(this);
  }

  bind() {
    this.navToggleButton.addEventListener('click', this.handleToggleNavListener);
  }

  handleToggleNav(event) {
    event.preventDefault();
    this.elem.classList.toggle('is-nav-opened');
    // document.body.classList.toggle('no-scroll');

    // if (this.documentation && this.documentation.classList.contains('is-menu-opened')) {
    //   this.documentation.classList.remove('is-menu-opened');
    //   document.body.classList.add('no-scroll');
    // }
  }

  init() {
    this.bind();
  }
}
