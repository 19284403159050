import { debounce } from '../../utils/debounce';

import './anim-logo.css';

export default class AnimLogo {
  constructor() {
    this.elem = document.querySelector('.js-anim-logo');
    if (!this.elem) return;

    this.orig = this.elem.querySelector('.js-anim-logo__orig');
    this.overlay = this.elem.querySelector('.js-anim-logo__overlay');

    this.originCoords = { x: 0, y: 0 };
    this.currentCoords = { x: 0, y: 0 };

    this.dimensions = { width: 0, height: 0 };

    this.handleMouseLeaveListener = this.handleMouseLeave.bind(this);
    this.handleMouseMoveListener = this.handleMouseMove.bind(this);
    this.handleResizeListener = this.handleResize.bind(this);
  }

  bind() {
    this.overlay.addEventListener('mouseenter', this.handleMouseMoveListener);
    this.overlay.addEventListener('mouseleave', debounce(this.handleMouseLeaveListener));
    this.overlay.addEventListener('mousemove', debounce(this.handleMouseMoveListener));
    window.addEventListener('resize', this.handleResizeListener);
  }

  unbind() {
    this.elem.removeEventListener('mouseenter', this.handleMouseMoveListener);
    this.elem.removeEventListener('mouseleave', this.handleMouseLeaveListener);
    this.elem.removeEventListener('mousemove', this.handleMouseMoveListener);
    window.removeEventListener('resize', this.handleResizeListener);
  }

  resetOriginCoords() {
    this.originCoords.x = this.elem.offsetLeft + Math.floor(this.dimensions.width / 2);
    this.originCoords.y = this.elem.offsetTop + Math.floor(this.dimensions.width / 2);
  }

  isTimeToUpdate() {
    // eslint-disable-next-line no-plusplus
    return this.updateCounter++ % 10 === 0;
  }

  handleMouseLeave() {
    this.orig.style.transform = '';
  }

  handleMouseMove(event) {
    this.update(event);
  }

  handleResize() {
    /* TODO: do not start animation if logo is not visible */
    this.dimensions.width = this.elem.offsetWidth;
    this.dimensions.height = this.elem.offsetHeight;
    this.resetOriginCoords();
  }

  updateCoords(event) {
    const e = event || window.event;
    this.currentCoords.x = e.clientX - this.originCoords.x;
    this.currentCoords.y = (e.clientY - this.originCoords.y) * -1;
  }

  updateTransformStyle() {
    const x = (this.currentCoords.x / this.dimensions.width / 2).toFixed(2);
    const y = (this.currentCoords.y / this.dimensions.height / 2).toFixed(2);

    this.orig.style.transform = `rotateX(${y}deg) rotateY(${x}deg)`;
  }

  update(event) {
    this.updateCoords(event);
    this.updateTransformStyle();
  }

  init() {
    if (!this.elem || !this.orig || !this.overlay) return;

    this.handleResize();
    this.bind();
  }
}
